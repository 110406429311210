import { getObjectValue } from "./utills";

export const Theme = {
    
    darkTheme:{
      background_color:'#000',
      primary_text: '#FFF',
      secondary_text: '#E6E1E5',
      modifierSelectedText:'#000',
      primaryBtn: '#6A4DAE',
      btn_font_color: '#FFF',
      btn_font_color_disabled: ' #A0A0A0',
      secondaryBtn: '#0060FF',
      secondaryBtnDisabled : '#345388',
      primary_btn_disable:'#636363',
      allStageBtn_disable : '#6A4DAE',
      secondary_btn_disable: '#345388',
      secondary_print_btn_disable: '#345388',
      secondary_btn_disable_text:'#A0A0A0',
      OFF_Premise_Btn:'#262626',
      cancleBtn_font:'#FFF',
      cancleBtn_Border:'1px solid #0060FF',
      tile_bg:'#262626',
      tile_bg_more:'#262626',
      card_bg:'#2D2D2D',
      card_border:'1px solid #525252',
      card_border_color:'#525252',
      popUp_bg:'#2F2F2F',
      popUp_box_shadow: '0px 8px 36px 0px rgba(0, 0, 0, 0.16)',
      modal_bg:'rgba(0, 0, 0, 0.67)',
      popupBorder: '1px solid #9F9F9F',
      popupBorderMerge: '1px solid #525252',
      inputBorder_Bottom: '2px solid #85888C',
      inputBg:'#393F44',
      input_placeholder:'#ACABAB',
      seat_bg:'#FFF',
      seat_box_text:'#000',
      selected:'#9EBEFB',
      unselected:'#4B4B4B',
      noRecord:'#A0A0A0',
      iconcolor:'#FFF',
      secondary_iconcolor:'#9EBEFB',
      iconcolor_bg_box:'#433E50',
      error_text:'#DA1B41',
      checkbox_border:'2px solid #FFF',
      checkbox_checked:'#5081DE',
      checkbox_check_color: '#000',
      keyBoard_Bg_Color:'#2A2A2A',
      keyBoard_key_Color: '#646464 ',
      selectedCheck_bg:'#404040',
      select_border:'none',
      pending_items:'#6E6572',
      send_tab_unselected:'#292C2D',
      menu_btn_bg:'#797979',
      menu_btn_bg_two:'#797979',
      menu_btn_disable_bg:'#9585B9',
      menu_btn_disable_bg2 : '#9585B9',
      menu_btn_disable_text:'#B1B1B1',
      selector_control_color:'#4C4C4C',
      drop_down_selected: '#3C3C3C',
      receipt_select_bg:'#1B1B1B',
      qrLinkMinusColor: '#AD0028',
      qrLinkPhoneNumberAddBgColor: '#DDD',
      notification_box_border:'1px solid #767676',
      notification_time_font: '#B1B1B1',
      acknowledged_btn : '#21CA46',
      toggleButton_switch: '#DDD',
      toggleButton_bg: 'transparent',
      contact_support: '#FFF',
      gameCard_selected_tab:'#6e6572',
      gameCard_table_border: '1px solid #525252',
      search_field_bg: '#191919',
      check_search_field_bg: '#433E50',
      keyBoard_Specific_Btn_color:'#3F3F3F',
      modifiers_border: '#646464',
      modifier_separator: '#525252',
      gameCard_error_bg: '#CF6679',
      optional_modifier_bg:'#474747',
      modifiers_plus_minus: '#2F2F2F',
      modifiers_tile_bg: '#636363',
      Category_text:'#9EBEFB',
      global_mod_tile:'#292C2D',
      notes:'#FFED91',
      optional_mod_tile:'#232323',
      drop_left_modifier:'#464646',
      itemFacts:'#79747E',
      checkDetail_tabs:'#4C4C4C',
      NO_RECEIPT: '#9EBEFB',
      tip_tile:'#2F2F2F',
      tip_border:'transparent',
      coupon_points: '#FFA700',
      coupon_selected_tile:'#B1B1B1',
      coupon_tile_border:'#292C2D',
      SKIP_OFFER_btn:'#575757',
      survey_star_bg:'#7A7A7A',
      survey_star_fill:'#FFD700',
      survey_star_stroke:'#F5E8A1',
      survey_select_border:'#fff',
      stage_seat_border: '#85888C',
      stage_tab_button: '#161616',
      stage_tab_button_selected:'#2F2F2F',
      stage_table_number:'#7E7E7E',
      stage_payment_border:'#4C4C4C',
      orders_selected_category:'#6E6572',
      order_category_selectedOption:' 2px solid rgba(255, 255, 255, .5)',
      orders_Unselected_category:'#4C4C4C',
      order_selector_border:'1px solid #9F9F9F',
      orderitem_bg:"transparent",
      link_color:'#9EBEFB',
      toastError:'#E80028',
      order_item_Selected_bg:'#6E6572',
      order_item_border:'#6E6572',
      input_box_background:'#393F44',
      mod_group_name_bg:'#534C63',
      itemsBg:'#2F2F2F',
      subTotalBg:'#4C4C4C',
      modOptionBg:'#232323',
      modifier_arrowDown:'#585858',
      pagination_btn_text: '#E8E8E8',
      pagination_btn_colour: '#433E50',
      pagination_btn_border: '#433E50',
      pagination_border: '1px solid #79747E',
      pagination_ChangeBtn: '#433E50',
      pagination_active: '#9EBEFB',
      offers_Border: '1px solid #9F9F9F',
      scp_bg: '#1C1D22',
      strokeLine: '#363636',
      disabledPayBtn: '#4B624C',
      enabledPayBtn: '#29A52B',
      itemQtyClr: '#BBBBBB',
      splitUnselectedTile : '#191919',
      splitSelectedTile: '#2F2F2F',
      splitSelectedBorder: '1px solid #FFF',
      noItemContainers: '#A0A0A0',
      seat_tiles: '#2F2F2F',
      seatItemsDisplay: '#7E7E7E',
      seatItemsDisplayBorder: '',
      cartSelectedBorder: '',
      dropdownSelectedPhone: '#216BA5',
    },
    lightTheme: {
        background_color: '#F5F7FB',
        primary_text: '#000',
        modifierSelectedText: '#000',
        secondary_text: '#787878',
        primaryBtn: '#997DDB',
        btn_font_color: '#FFF',
        btn_font_color_disabled: ' #A0A0A0',
        secondaryBtn: '#0060FF',
        primary_btn_disable: '#E5E5E5',
        secondary_btn_disable_text: '#A0A0A0',
        secondary_btn_disable: '#345388',
        secondaryBtnDisabled: '#345388',
        OFF_Premise_Btn: '#FFF',
        cancleBtn_font: '#000',
        cancleBtn_Border: '1px solid #0060FF',
        tile_bg: '#DEDEDE',
        tile_bg_more: "#EDEDED",
        card_bg: '#FFF',
        card_border: '1px solid #999',
        card_border_color: '#999',
        popUp_bg: '#F5F7FB',
        popUp_box_shadow: '0px 8px 36px 0px rgba(0, 0, 0, 0.16)',
        modal_bg: 'rgba(255, 255, 255, 0.70)',
        popupBorder: '1px solid #999',
        popupBorderMerge: '1px solid #999',
        inputBorder_Bottom: '2px solid #DDD',
        inputBg: '#FFF',
        input_placeholder: '#787878',
        seat_bg: '#515151',
        seat_box_text: '#FFF',
        selected: '#D2E2FF',
        unselected: '#EDEDED',
        noRecord: '#A0A0A0',
        iconcolor: '#000',
        secondary_iconcolor: '#222222',
        iconcolor_bg_box: '#E6E6E6',
        error_text: '#DA1B41',
        checkbox_border: '2px solid #454545',
        checkbox_checked: '#5081DE',
        checkbox_check_color: '#fff',
        keyBoard_Bg_Color: '#CACDD4',
        keyBoard_key_Color: '#FFF',
        selectedCheck_bg: '#FFF',
        select_border: '1px solid #999',
        pending_items: '#D2E2FF',
        send_tab_unselected: '#EDEDED',
        menu_btn_bg: '#EDEDED',
        menu_btn_bg_two: '#E5E5E5',
        menu_btn_disable_bg: '#C9C9C9',
        menu_btn_disable_bg2: "#9585b9",
        menu_btn_disable_text: '#A0A0A0',
        selector_control_color: '#FFF',
        drop_down_selected: '#EDEDED',
        receipt_select_bg: '#FFF',
        qrLinkMinusColor: '#EB5252',
        qrLinkPhoneNumberAddBgColor: '#FFF',
        notification_box_border: '1px solid #D8D8D8',
        notification_time_font: '#000',
        acknowledged_btn: '#29A52B',
        toggleButton_switch: '#0060FF',
        toggleButton_bg: '#E6E0E9',
        contact_support: '#787878',
        gameCard_selected_tab: '#D2E2F2',
        gameCard_table_border: '1px solid #D8D8D8',
        search_field_bg: 'white',
        keyBoard_Specific_Btn_color: '#AEB3BE',
        modifiers_border: '#999',
        modifier_separator: '#999999',
        gameCard_error_bg: '#EB5252',
        optional_modifier_bg: '#EDEDED',
        modifiers_plus_minus: '#E5E5E5',
        modifiers_tile_bg: '#EDEDED',
        Category_text: '#5081DE',
        global_mod_tile: '#fff',
        notes: '#9E7B00',
        optional_mod_tile: '#F5F7FB',
        drop_left_modifier: '#C9C9C9',
        itemFacts: '#999',
        checkDetail_tabs: '#EDEDED',
        NO_RECEIPT: '#0060FF',
        tip_tile: '#EDEDED',
        tip_border: '#999',
        coupon_points: '#9E7B00',
        coupon_selected_tile: '#FFF',
        coupon_tile_border: '#999',
        SKIP_OFFER_btn: '#FFF',
        survey_star_bg: '#F5F5F5',
        survey_star_fill: '#FF9900',
        survey_star_stroke: '#FFD390',
        survey_select_border: '#999',
        stage_seat_border: '#999',
        stage_tab_button: '#EDEDED',
        stage_tab_button_selected: '#FFF',
        stage_table_number: '#515151',
        stage_payment_border: '#999',
        orders_selected_category: '#D2E2FF',
        order_category_selectedOption: '2px solid rgba(255, 255, 255, .5)',
        orders_Unselected_category: '#F5F7FB',
        order_selector_border: '1px solid #D8D8D8',
        orderitem_bg: '#EDEDED',
        link_color: '#0060FF',
        toastError: '#EB5252',
        order_item_Selected_bg: '#D2E2FF',
        order_item_border: '#999',
        check_search_field_bg: '#FFFFFF',
        input_box_background:'#fff',
        mod_group_name_bg:'#E6E6E6',
        itemsBg:'#EDEDED',
        subTotalBg:'#fff',
        modOptionBg:'#EDEDED',
        modifier_arrowDown:'#585858',
        pagination_btn_text: '#000000',
        pagination_btn_colour: '#E6E6E6',
        pagination_btn_border: '#E6E6E6',
        pagination_border: '1px solid #999999',
        pagination_ChangeBtn: '#E6E6E6',
        pagination_active: '#0060FF',
        offers_Border: '1px solid #9F9F9F',
        scp_bg: '#FFF',
        strokeLine: '#999',
        disabledPayBtn: '#738E74',
        enabledPayBtn: '#29A52B',
        itemQtyClr: '#787878',
        splitUnselectedTile: '#EDEDED',
        splitSelectedTile: '#FFF',
        splitSelectedBorder: '1px solid #999',
        noItemContainers: '#787878',
        seat_tiles: '#515151',
        seatItemsDisplay: '#F4F4F4',
        seatItemsDisplayBorder: '1px solid #BBB',
        cartSelectedBorder: '1px solid #999',
        dropdownSelectedPhone: '#216BA5',
    },
};



export const toggleServerAppTheme = () => {
    const getTheme = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).serverAppTheme);

    return getTheme === '1' ? Theme.darkTheme : Theme.lightTheme

}
export const toggleKdsTheme = () => {
    const getTheme = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).kdsTheme);

    return getTheme === '2' ? Theme.lightTheme : Theme.darkTheme

}
export default {
  // Login Screen
  LOGIN: 'Login',
  KIOSK_LOGIN: 'LOGIN',
  ACTIVATE_INGENICO_DEVICE: 'ACTIVE INGENICO DEVICE',
  CANCEL: 'Cancel',
  KIOSK_CANCEL: 'CANCEL',
  CONTACT: 'contact',
  FOR_SUPPORT: 'For support',
  CLICK_TO_SWIPE_CARD : 'CLICK TO SWIPE',

  // Check Listing Screen
  TABLE: 'Table',
  CHECKS: 'Check#',
  TAB: 'Tab',
  OTHER: 'Other',
  OPEN_SCAN: 'Scan',
  OPEN_TABLE: 'Table',
  OPEN_TAB: 'Tab',
  OPEN_OTHER: 'Other',
  ALL_CHECKS: 'All Checks',
  OPEN_CHECKS: 'Open Checks',
  LOYALTY_ACCOUNT_BALANCE: 'Loyalty Account Balance',
  MY_TIPS: 'My Tips',
  PAIR_WATCH: 'Pair Watch',
  ABOUT: 'About',
  PAIR: 'Pair',
  SYNC_CHECKS: 'Sync Checks',
  CHANGE_JOB: 'Change Job Type',
  SENT: 'Sent',
  PENDING: 'Pending',
  ORDER: 'Order',
  PRINT: 'Print',
  EDIT_TIP_PERCENTAGE: 'Edit Tip Percentage',
  ADD_PHONE_NUMBER: 'Add Phone Number',
  ADD: 'Add',
  ACKNOWLEDGED: 'Acknowledged',
  DISMISS: 'Dismiss',
  NOTIFICATIONS: 'Notifications',
  // EDIT_SEATS: 'Edit Seats',

  STAGE: 'Stage',
  PRE_AUTH: 'Pre Auth',
  PAY: 'Pay',
  PAY_NOW: 'Pay Now',
  RECEIPT: 'Receipt',
  TOOLS: 'More',
  SELECT_CARD: 'Select a card',
  CARD_TYPE_NAME: 'Card Type',
  CARD: 'Card #',
  HOLDER_NAME: 'Card Holder Name',
  SELECT_BTN: 'Select',
  SELECT: 'Select',
  BUSINESS_DATE: 'Business Date:',
  CHECK: 'Check #:',
  SERVER: 'Server:',
  PRE_TOTAL_AMOUNT: 'Total Amount',
  PRE_PAID_AMOUNT: 'Paid Amount:',
  PRE_UNPAID_AMOUNT: 'Unpaid Amount:',
  PRE_DUE_AMOUNT: 'Due Amount:',
  DELETE: 'Delete',
  DELETE_ALL: 'Delete All',
  SEND_ALL: 'Send All',
  EDIT: 'Edit',
  ENABLE_RESTRICTED: 'Enable Restricted',
  DISABLE_RESTRICTED: 'Disable Restricted',
  MOVE: 'Move',
  SEND: 'Send',
  SEARCH: 'Search',
  NO: 'No',
  KIOSK_NO: 'NO',
  YES: 'Yes',
  KIOSK_YES: 'YES',
  OPEN: 'Open',
  GUEST_COUNT: 'Guest Count',
  NO_CHECKS: 'No Checks',
  REPEAT: 'Repeat',
  SELECT_JOB: 'Select Job',
  OFF_PREMISE_ORDERS: 'Off Premise Orders',
  PHONE_NUMBER: 'Phone Number',
  GUEST_NAME: 'Guest Name',
  PICKUP_TIME: 'Pickup time',
  DELIVERY_TIME: 'Delivery Time',
  CALL_IN: 'Call In',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  CITY: 'City',
  STATE: 'State',
  ZIP: 'Zip',
  SYNC_CHECKS_WITH_POS: 'Sync Check with POS',
  SEND_QR_LINK: 'Send QR Link',
  PRINT_TABLE_QR: 'Print Table QR',
  REPOST_PAYMENT: 'Repost Payment',
  PRE_AUTH_PAYMENT: 'Complete Pre Auth Payment',
  SUBMIT: 'Submit',
  APPLY: 'Apply',
  KIOSK_SUBMIT: 'SUBMIT',
  SAVE: 'Save',
  EMAIL: 'Email',
  TEXT: 'Text',
  VOID: 'Void',
  EMP: 'Emp',
  TIME: 'Time',
  PAID: 'Paid',
  TAKE_OUT: 'Walk Up',
  SELECT_EMPLOYEE: 'Select Employee',
  SELECT_AN_EMPLOYEE: 'Select an Employee',
  SELECT_TABLE: 'Select a Table',
  EMPLOYEE: 'Employee',
  UPDATE_CHECK_NAME: 'Update Check Name',
  SELECT_EMP: 'Select Employee',
  NAME: 'Name',
  CHANGE_ORIENTATION: 'Change Screen Orientation',
  JOB_TYPE: 'Job type',
  SELECT_ORIENTATION: 'Select Screen Orientation',
  LEFT: 'Left',
  RIGHT: 'Right',

  //CheckListing Header lables
  CHECK_LISTING_TABLE_NUMBER: 'Table',
  CHECK_LISTING_CHECK_NUMBER: 'Check#',
  CHECK_LISTING_OWNER: 'Owner',
  CHECK_LISTING_PAID: 'Paid',
  CHECK_LISTING_BALANCE: 'Balance',
  CHECK_LISTING_TIME: 'Time',

  CHECK_LISTING_TOTAL: 'Total',
  LAUNCH_EXTERNAL_APP: 'Launch External App',
  TOGGLE_TEAM_CHECKS: 'Team Checks',
  TOGGLE_MY_CHECKS: 'My Checks',
  TOTAL_REMAINING: 'Total Remaining',
  CHECK_NUM: '#',
  SEND_SMS: 'Send SMS',
  SENDSMS: 'Send SMS',
  TABLE_TRANSFER: 'Transfer Table',
  REASSIGN_EMPLOYEE: 'Reassign Employee',
  REMOVE: 'Remove',
  TAX_EXEMPT: 'Tax Exempt',
  // Order Screen

  CLEAR: 'Clear',
  APPLY: 'Apply',
  DISCOUNT: 'Discount',
  DISCOUNT_OPEN: 'Discount Open',
  DUE: 'Due',
  TOTAL: 'Total',
  VIEW: 'View',
  MODIFY: 'MODIFY ITEM',
  OCCUPY_SEAT: 'Occupy Seat',
  SEAT: 'SEAT',
  SEATS: 'SEATS',
  OK: 'OK',
  REFILL_REMOVE: 'REMOVE',
  TRANSFER: 'Transfer',
  TRANSFER_EMPLOYEE: 'Transfer Employee',
  TABLE_MERGE: 'Table Merge',
  DELETE_DISCOUNT: 'Delete',
  TRANSFER_TABLE: 'Transfer Table',
  VOID_CHECk: 'Void Check',
  KEEP_OPEN: 'Keep Open',
  RETRY_: 'Retry',
  MERGE_CHECK: 'Merge Check',
  GAME_CARD: 'Game Card',
  RETRY: 'Retry',
  ORDER_NOW: 'Order Now',
  CHECKOUT: 'CHECKOUT',
  SEND_NOW: 'Send now',
  CART: 'Cart',
  CLOSE: 'Close',
  KIOSK_CLOSE: 'CLOSE',
  SMS: 'Text Receipt',
  TOGO: 'To-Go',
  ON_THE_FLY: 'On the fly',
  RUNNER: 'Runner',
  DO_NOT_MAKE: 'Do not make',
  RESTART_ORDER: 'Restart Order',
  MORE_INFO: 'MORE INFO',
  TBL: 'TBL',
  NEW_CHECK: 'Start New Check',
  GO_TO_CHECK: 'Go To Check',
  SEND_ORDER: 'SEND ORDER',
  OPEN_DOLLAR_ITEM: 'Open Dollar Item',
  SELECT_A_SEAT_TO_DELETE: 'Select a seat to delete',
  DELETE_SEAT: 'Delete Seat',
  ADD_SEAT: 'Add Seat',
  // Order details

  ADD_TO_CART: 'Add to Cart',
  UPDATE_CART: 'Update Cart',
  UPDATE_ORDER: 'Update Order',
  SPECIAL_INSTRUCTIONS: 'SPECIAL INSTRUCTIONS',
  ADD_TO_BAG: 'Add to Bag',
  ADD_A_NOTE: 'Add a note',
  ADDITIONAL_TIME: 'Additional Time',
  VIEW_ONLY: 'View Only',
  SPECIAL: 'Special',
  ORDER_ANOTHER: 'ORDER ANOTHER',
  COMING_SOON: 'Coming soon',
  LIMITED_QUANTITY: 'Limited quantity',
  TIME_RESTRICTED: 'Time restricted',
  FEATURED: 'Featured',
  OUT_OF_STOCK: 'Out of Stock',
  LIMITED_TIME: 'Limited Time',
  NEW_ITEM: 'New Item',
  OUT_OF_STOCK_NUMBER: '86',
  ITEM_FACTS: 'Item Facts',
  NUTRITION_FACT: 'Nutrition facts',

  // STAGE

  GOING_DUTCH: 'Going Dutch',
  CLEAR_ALL: 'Clear All',
  Edit_seat: 'Edit Seats',
  NO_STAGES: 'No stages',
  ALL_STAGES: 'All Stages',
  STAGE_PAY: 'PAY',

  // Payment Screen

  DONE: 'Done',
  SUB_TOTAL: 'Subtotal',
  TAX: 'Tax',
  VAT: 'VAT',
  PAYMENT: 'Payment',
  DELIVERY: 'Delivery',
  PAY_AN_AMOUNT: 'Pay An Amount',
  SPLIT_EVENLY: 'Split Evenly',
  TIP: 'Tip',
  PAYMENT_COMPLETED: 'PAYMENT COMPLETED',
  PAYMENT_COMPLETED_VIA: 'PAYMENT IS NOW COMPLETE. Paid with',
  PAYMENT_FAILED: 'PAYMENT FAILED',
  CURRENCY_SYMBOL: '$',
  PERCENTAGE_SYMBOL: '%',
  NO_RECEIPT: 'No receipt',
  PRINT_RECEIPT: 'Print Receipt',
  TEXT_RECEIPT: 'Text Receipt',
  EMIAL_RECEIPT: 'Email ReceiptT',
  CONTINUE: 'Continue',
  CONTINUE_TO_GUEST: 'Continue To Next Guest',
  KIOSK_CONTINUE: 'CONTINUE',
  SKIP_SURVEY: 'SKIP SURVEY',
  ENTER_MOBILE_NUMBER: 'Enter Mobile Number',
  ENTER_ACCOUNT_NUMBER: 'Enter Account Number',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  ENTER_TABLE_NAME: 'Enter Table Name',
  PAY_FULL_CHECK: 'Pay Full Check',
  CASH: 'Cash',
  KIOSK_CASH: 'CASH',
  TEXT_TO_PAY: 'Text To Pay',
  CREDIT_CARD: 'Credit card',
  KIOSK_CREDIT_CARD: 'CREDIT CARD',
  DEBIT_CARD: 'Debit Card',
  UNPAID: 'UNPAID',
  PRICE_CHECK: 'Price Check',
  CONFIRM: 'Confirm',
  PAYEMENT_TOTAL_DUE: 'Total Due',
  PAYMENT_TOTAL: 'Total',
  PAYMENTS: 'Payments',
  SERVICE_CHARGE: 'Service Charge',
  DISCOUNT_AMOUNT: 'Discount',
  TIP_AMOUNT: 'Tip',
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
  PAY_REMAINING_BALANCE: 'Pay Balance',
  PAY_CHECK: 'Pay Check',
  TOTAL_PAID: 'Total paid',
  BALANCE: 'Balance',
  GIFT_BALANCE: 'Gift Card Balance',
  PAY_NOW: 'Pay Now',
  GIFT_CARD: 'Gift card',
  SIGNATURE: 'Signature',
  SWIPE_CARD: 'Click to swipe card',
  CLICK_TYPE: 'Click to type',
  CHECK_BALANCE: 'Check balance',
  TIPS: 'Tips',
  PAID_TIPS: 'Paid Tip',
  UNPAID_TIPS: 'Unpaid Tip',
  DISCOUNTS: 'Discounts',
  LOCATION: 'Location',
  TOTAL_DUE: 'Total Due',
  RECEIPT_PAYMENT: 'Payments',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  PAYMENT_RECEIPT: 'PLEASE SELECT A RECEIPT OPTION',
  ENTER_TIP: 'ENTER TIP',
  SPLIT_BY: 'SPLIT BY',
  INFORMATION: 'Information',
  EMPLOYEE_LOGIN: 'Employee Login',
  CALL_SERVER: 'Call Server',
  OTHER_OPTIONS: 'Other Options',
  OPT_IN: 'Opt In',
  CREDIT_CARD_OR_CONTACTLESS: 'CREDIT CARD OR CONTACTLESS',
  PAYMENT_NOW_COMPLETE: 'YOUR PAYMENT IS NOW COMPLETE.',
  CASH_PAYMENT: 'Cash',
  TOTAL_PAYMENT_PAID: 'TOTAL PAID',
  TOTAL_DUE_PAYMENT: 'TOTAL DUE',
  NO_RECEIPT_OPTION: 'NO RECEIPT',
  RECEIPT_TEXT_IT_TO_ME: 'TEXT IT TO ME',
  RECEIPT_EMAIL_IT_TO_ME: 'EMAIL IT TO ME',
  GO_TO_RECEIPT: 'Go To Receipt',
  CONFIRM_ID_CHECK: 'Confirm Id Check',
  CLEAR_ID_CHECK: 'Clear Id Check',
  EXTERNALCREDIT_CARD: 'External Credit Card',
  ENABLE_AGE_RESTRICTED: 'Enable Age Restricted Items',
  DISABLE_AGE_RESTRICTED: 'Disable Age Restricted Items',
  PREAUTH_CARD: 'CLICK TO PAY',
  BALANCE: 'Balance',
  APPLIED: 'Applied',
  TOTAL: 'Total',
  ITEMIZED: 'Itemized',
  APPROVED: 'Approved',
  YOU_ARE_ALL_DONE: `You're all done!`,
  NEED_A_RECEIPT: 'Need a Receipt?',
  NO_RECEIPT: 'No Receipt',
  // LOYALTY
  JOIN_NOW: 'Join Now',
  ACCOUNT_NUMBER: 'Account Number',
  ALREADY_A_MEMBER: 'Already a Member',
  NOT_NOW: 'Not Now',
  AVAILABLE_OFFERS: 'Available offers',
  NO_OFFERS: 'No offers available',
  QR_CODE: 'QR code',
  SKIP_OFFER: 'Skip Offer',
  APPLY_OFFER: 'Apply Offer',
  USE_COUPON: 'Use Coupon',
  COUPON_CANCEL: 'Cancel',
  APPLY_CODE: 'Apply Code',
  LOYALTY_PHONE_NUMBER: 'Phone Number',
  LOYALTY_EMAIL: 'Email',
  LOYALTY_ACCOUNT: 'Account',
  POINTS: 'Points',
  TIER: 'Tier',
  LOYALTY_SWIPE: 'Swipe',
  LOYALTY_SCAN: 'Scan',
  LOYALTY_PHONE_PLACEHOLDER: 'Enter Phone Number',
  LOYALTY_ACCOUNT_PLACEHOLDER: 'Enter Account Number',
  LOYALTY_EMAIL_PLACEHOLDER: 'Enter email-id',
  SIGN_IN_TO_LOYALTY: 'SIGN IN TO LOYALTY',
  SIGN_UP_TO_LOYALTY: 'SIGN UP TO LOYALTY',

  // OFFERS SCREEN
  OS_DUE: 'Due',
  OS_ITEMIZED_CHECK: 'Itemized Check',
  OS_SIGN_IN_TO_LOYALTY: 'Sign in to Loyalty',
  OS_OFFER: 'Offer',
  OS_NO_OFFERS: 'NO OFFERS',
  OS_VIEW_OFFERS: 'VIEW OFFERS',
  OS_USE_A_COUPON: 'Use a Coupon',
  OS_COUPON: 'Coupon',
  OS_ADD_A_GIFT_CARD: 'Add a Gift card',
  OS_BALANCE: 'Balance',
  OS_REDEEMED: 'Redeemed',
  OS_CARD_READY:
    'Please have your card ready before you proceed. Click the Pay button, when ready.',
  OS_PAY_WITH_CASH_OR_MULTIPLE_CARDS: 'Pay with cash or multiple cards',
  OS_PAY_WITH_CARD: 'Pay with card',
  OS_COMPLETE_PAYMENT: 'Complete payment',

  // Date Format
  DATE_FORMAT_LONG: 'MM/DD/YYYY',
  DATE_FORMAT_SHORT: 'MM/DD/YY',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_UK_LONG: 'DD/MM/YYYY',

  // Kiosk
  KIOSK_OK: 'OK',
  SHOW_MORE: 'show more',
  SHOW_LESS: 'show less',
  KIOSK_CUSTOM_AMOUNT: 'CUSTOM AMOUNT',
  KIOSK_CUSTOM_PERCENT: 'CUSTOM PERCENT',
  BACK_TO_CART: 'BACK TO CART',
  CONTINUE_ORDER: 'CONTINUE ORDER',
  START_OVER: 'START OVER',
  START_ORDER: 'Start order',
  KIOSK_START_ORDER: 'START ORDER',
  PAY_AT_COUNTER_WITH_CASH: 'PAY AT COUNTER WITH CASH',
  PAY_WITH_CREDIT_DEBIT: 'PAY HERE WITH CREDIT / CREDIT',
  NO_THANKS: 'NO, THANKS',
  ORDER_MORE_ITEMS: 'ORDER MORE ITEMS',
  PAY_WITH_CARD: 'PAY WITH CARD',
  VIEW_MY_ORDER: 'VIEW MY ORDER',
  ORDER_NAME: 'ORDER NAME',
  ORDER_FOR_HERE: 'ORDER FOR HERE',
  ORDER_TO_GO: 'ORDER TO-GO',
  REQUIRED: 'REQUIRED',
  ACCESSIBILITY: 'ACCESSIBILITY',
  NEED_ASSISTANCE: 'NEED ASSISTANCE?',
  SELECT_TIP: 'SELECT TIP',
  CONTINUE_WITH_PAYMENT: 'CONTINUE WITH PAYMENT',
  NAME_REQUIRED: 'NAME (required)',
  NAME_OPTIONAL: 'NAME (optional)',
  PHONE_OPTIONAL: 'PHONE (optional)',
  PHONE_REQUIRED: 'PHONE (required)',
  SCAN_TO_PAY: 'SCAN TO PAY',
  PAY_WITH_CASH: 'PAY WITH CASH',
  BACK_TO_TIP: 'BACK TO TIP',
  SEND_TEXT: 'SEND TEXT',
  SEND_EMAIL: 'SEND EMAIL',
  TEXT_IT_TO_ME: 'TEXT IT TO ME',
  EMAIL_IT_TO_ME: 'EMAIL IT TO ME',
  NO_THANK_YOU: 'NO, THANK YOU',
  ADD_TO_ORDER: 'ADD TO ORDER',
  RETURN_TO_MENU: 'RETURN TO MENU',
  ADD_NOTE: 'ADD NOTE',
  PAYMENT_DUE: 'PAYMENT DUE',
  TYPE_YOUR_NOTE: 'TYPE YOUR NOTE',
  PAY_AT_COUNTER: 'PAY AT COUNTER',

  // tableTop
  START_NEW_ORDER: 'START NEW ORDER',
  SPEED_ORDER: 'Speed order',
  MAKE_PAYMENT: 'Make Payment',
  ORDER_ITEMS: 'Order Items',
  NO_ITEMS_ADDED: 'No Items Added',
  ASSIGN_TABLE: 'Assign Table',
  EDIT_ITEMS: 'Edit Items',
  UNSENT_ITEMS: 'UNSENT ITEMS',

  // Fire Board
  CARD_TYPE: 'Type',
  REDACTED_CARD_NUMBER: 'Card #',
  SWIPE_CARD_HERE: 'Swipe Card Here',

  // ----------------------------------------------------------------------------

  NOTIFICATION_ID: 'notificationId',
  STORE_ID: 'storeId',
  NOTIFICATION_TYPE_ID: 'notificationTypeId',
  CATEGORY: 'category',
  SEVERITY: 'severity',
  ENTER_EMAIL_ID: 'Email Address',
  SHORT_NOTE: 'shortNote',
  ID: 'id',
  NUMBER: 'Number',
  EMPLOYEE_ID: 'employeeId',
  ITEM_AMOUNT: 'Item Amount',
  STATION_NAME: 'Station Name',
  IMAGE: 'image',
  NOW: 'Now',
  LATER: 'Later',
  CALL: 'Call',
  GET_OTP: 'Get Code',
  GO_BACK: 'GO BACK',
  GET_EMAIL_OTP: 'Get security code by Email',
  GET_MOBILE_OTP: 'Get security code by Text',
  VERIFY_YOUR_PHONE: 'Verify Your Phone',
  CHECK_IN: 'Check In',
  CHECK_IN_QR: 'Check in with a QR code',
  ENTER_TABLE_NUMBER: 'Enter My Table Number',
  SCAN_TABLE_CODE: 'Scan My Table Code',
  LEAVE_TABLE: 'Exit check',
  LOADING: 'Loading',
  RESEND_OTP: 'Resend Code',
  RESEND_IN: 'Resend in',
  SECONDS: 'seconds',
  BACK: 'Back',
  SAVE_CHANGES: 'Save changes',
  YES_CONTINUE: 'Yes, continue',
  TRY_AGAIN: 'Try Again',
  SIGN_UP: 'SIGN UP',
  RESET_PASSWORD: 'Reset Password',
  SUBMITING: 'Submiting',
  SPLIT_EQUALY: 'Split Equaly',
  SELECT_BY_ITEMS: 'Select Items To Pay',
  SKIP: 'Skip',
  VERIFY: 'VERIFY',
  TEXT_RECEIPT: 'Text Receipt',
  EMAIL_RECEIPT: 'Email Receipt',
  YOUR_RECEIPT: 'Your Receipt',
  VIEW_RECEIPT: 'View Receipt',
  VIEW_DETAILS: 'View details',
  GENERATE_OTP: 'Generate Code',
  ADD_TIP: ' ADD TIP',
  CONFIRMATION: 'Confirmation',
  RESTAURANT: 'Restaurant',
  VEHICLE_DESCRIPTION: 'Vehicle Description',
  SPOT_NUMBER: 'Spot Number',
  I_AM_HERE: 'I AM HERE',
  CHECK_IN_WITH_CODE: 'Check in with a 7-digit code',
  ADD_A_TIP: 'Add a tip',
  NO_TIP: 'No Tip',
  CHECK_TOTAL_TIP: 'Your check total is',
  PLEASE_SELECT_TIP: 'Please select a Tip Amount',
  TIP_CONTINUE: 'CONTINUE WITH NEW TOTAL',
  CUSTOM_AMOUNT: 'CUSTOM AMOUNT',
  CUSTOM_PERCENT: 'CUSTOM PERCENT',
  OTHER_AMOUNT: 'Other Amount',
  OTHER_PERCENT: 'Other Percentage',

  // label
  ENTER_EMAILID_MESSAGE:
    'Please enter the email you use to sign in to OneDine.',
  SENDING_OTP: 'Sending',
  PLEASE_SELECT_OPTION: 'Please select one of the following options',

  // Form Label
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  NICK_NAME: 'Order Nickname',
  USER_NAME: 'User Name',
  EMAIL_ADDRESS: 'Email Address',
  ENTER_USERNAME_EMAIL: 'Username',
  ENTER_PASSWORD: 'Password',
  ENTER_OTP: 'Enter Code',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  COUNTRY_CODE: 'Country Code',
  ENTER_FIRST_NAME: 'First Name',
  ENTER_LAST_NAME: 'Last Name',
  ENTER_DESIRED_USERNAME: 'Username',
  ENTER_CONFIRM_PASSWORD: 'Confirm Password',
  ENTER_THE_OTP: 'Verification Code',
  CODE: 'Code',
  COMMENTS: 'Comments',
  BRANCH: 'Branch',
  PASSWORD: 'Password',
  SURNAME: 'Surname',
  CARD_HOLDER: 'Card Holder',
  TABLE_NUMBER: 'Table Number',
  MOBILE_NUMBER: 'Enter Your Phone Number',
  SPOT_CODE: '7-Digit Code',
  CARD_HOLDER_NAME: 'Card Holder Name',
  ADDRESS_TYPE: 'Address type',
  AGREED_CONDITION: 'I agree with the terms and conditions.',
  USE_DIFFERENT_NUMBER: 'Use a different number',
  REQUEST_DETAILS: 'Request Details',
  REQUEST_DATA: 'REQUEST DATA',
  DELETE_DATA: 'DELETE DATA',
  PROVIDE_FEEDBACK: 'PROVIDE FEEDBACK',
  ENTER_AMOUNT: 'Enter an amount',

  // Other_tips Placeholders
  ENTER_PERCENTAGE: 'Enter percentage',
  ENTER_TIP_AMOUNT: 'Enter tip amount',

  // Navigations Tab labels
  HOME: 'Check In',
  OFFERS_PROMOTIONS: 'Offers & Promotions',
  WALLET: 'Payments',
  // LOYALTY_ACCOUNTS: 'Loyalty Accounts',
  ORDER_HISTORY: 'Past Orders',
  SIGN_OUT: 'Sign Out',
  MY_DATA: 'My Data',
  PROFILE: 'Profile',
  PREFERENCES: 'Email / SMS Preferences',
  LOGOUT: 'Log Out',
  ACCOUNT: 'My Account',
  SUBSCRIPTION: 'Subscription',
  TABLE_ORDER: 'TABLE',
  TAB_ORDER: 'TAB',
  OTHER_ORDER: 'OTHER',

  // Others
  YOUR_CHECK_TOTAL: 'Your Check Total',

  // select item page
  SELECT_ALL: 'Select All',
  CONTINUE_WITH_SELECTION: 'Continue with selection',
  SELECT_ALL_ITEMS_AND_CONTINUE: 'Select all items and continue',
  PAY_SELECTED: 'Pay Selected',
  PAY_ALL: 'Pay all',
  // TOTAL_AMOUNT: 'Total Amount',
  INCLUDING_TIP_NEW_TOTAL: 'Including tip, pay a total of',
  ACTIONS: 'Actions',
  EXPIRES_ON: 'Expires on:',
  READ_ONLY: '(read only)',
  EACH: ' each',
  YOUR_CHECK_SPLIT: 'Your check has been splited among ',
  MEMBERS: 'members with ',
  YOUR_ITEMS_CHECK_LIST: 'Your items check list',
  TOTALS: 'TOTAL',
  CHECK_TOTAL: 'Check total',
  STORE_SELECTION: 'Store Selection',
  SENSOR_ASSIGNMENT: 'Sensor Assignment',
  MANAGE: 'Manage',
  SENSOR: 'Sensor',
  TOO_FAST: 'Too Fast',
  TOO_SLOW: 'Too Slow',
  JUST_RIGHT: 'Just Right',
  TOTAL_AMOUNT: 'TOTAL',
  VALUE: 'Value',

  // Speed order

  ENTER_PHONE_OPTIONAL: 'Enter Phone (optional)',
  ENTER_PHONE_NUMBER: 'Enter Phone',
  FINALIZE_ORDER: 'Finalize Order',

  // Payment screen
  OFFERS: 'Offers',
  REWARDS: 'rewards',
  ROUND_UP: 'Round up',
  DELIVERY_FEE: 'Delivery fee',
  PICKUP: 'Pickup',
  REFRESH: 'Refresh',
  RETRY_PAYMENT: 'Retry Payment',
  SUBMIT_ORDER: 'Submit Order',
  COUPON: 'Coupon',
  NO_CHECK_AVAILABLE: 'Check not available',
  QR_PAY_FROM_PHONE: 'QR pay from phone',
  ESTIMATED_POINTS_EARNED: 'Estimated points earned',
  CHANGE: 'Change',
  FINALIZE: 'Finalize',
  DRAWER_OPEN: 'DRAWER OPEN',
  DRAWER_CLOSE: 'DRAWER CLOSED',
  AMOUNT_ENTERED: 'Amount Entered',
  PENDING_BALANCE: 'Pending Balance',
  CURRENT_BALANCE: 'Current Balance',
  AMOUNT_OWED: 'Amount Owed',
  AMOUNT_DUE: 'Amount Due',
  NOTHING_DUE_OWED: 'Nothing due or owed',
  SERVER_CASHOUT_REPORT: 'Server Cash Out Report',
  CASHIER_CASHOUT_REPORT: 'Cashier Cash Out Report',
  AMOUNT_OVER: 'Amount Over',
  AMOUNT_SHORT: 'Amount Short',
  NOTHING_OVER_SHORT: 'Nothing Over or Short',
  CASHOUT_SUMMARY: 'Cash Out Summary',
  QTY: 'Qty',
  ITEMS_LIST: 'Item',
  ITEMS_SEAT: 'Seat',
  AMOUNT: 'Amount',

  COLLECTED: 'Collected',
  PAPER: 'Print Receipt',
  NONE: 'No Receipt',
  CHANGE_PAYMENT_TYPE: 'Change Payment',
  BANK_AMOUNT: 'Bank Amount',
  PAID_IN_AMOUNT: 'Paid In Amount',
  PAID_OUT_AMOUNT: 'Paid Out Amount',
  CASH_DROP_AMOUNT: 'Cash Drop Amount',
  CASH_PAYMENT_AMOUNT: 'Cash Payment Amount',
  CREDIT_CARD_PAYMENT_AMOUNT: 'Credit Card Payment Amount',
  CREDIT_CARD_TIP_AMOUNT: 'Credit Card Tip Amount',
  PENDING_CASHOUT_AMOUNT: 'Pending Cash Out Amount',
  OVER_SHORT_AMOUNT: 'Over Short Amount',
  TIP_OUT: 'Tip Out',
  CASH_OUT_DEPOSIT_AMOUNT: 'Cash Out',
  SUGGESTED_TIP_MSG:
    '*Suggested tip is calculated on check amount before any applied discounts and after sales tax has been added',

  CLEAR_SPLITS: 'CLEAR SPLITS',
  SPLIT_BY_SEATS: 'SPLIT BY SEATS',
  SPLIT_BY_SELECTED_SEATS: 'SPLIT BY SELECTED SEATS',
  TOTAL_DUE: 'Total Due',
  OTHER_PAYMENT_OPTIONS: 'OTHER PAYMENT OPTIONS',
  SPLIT_BY: 'SPLIT BY',

  // Check Number screen
  CHECK_NUMBER: 'Check Number',
  FULL_CHECK: 'Full Check',
  SPLIT_ITEMS: 'Select Items',
  PAY_MY_ITEMS: 'Pay My Items',
  PAY_BY_GUEST: 'Pay By Guest',
  DINE_IN: 'Dine In',
  DC_NAME_OF_THIS_ORDER: 'Enter a name for this order',
  CASH_OUT: 'Cash Out',
  CASH_IN: 'Cash In',

  // Footer Screens
  FOOTER_HOME: 'Home',
  FOOTER_MENUS: 'Menus',
  FOOTER_ORDERS: 'Order',
  FOOTER_MY_BAG: 'My Bag',
  FOOTER_MORE: 'More',
  FAVOURITE: 'Favorite',
  BROWSE: 'Browse',
  FOOTER_STORE: 'Store',

  // HOME SCREEN, REFILL, DAISY CHAIN
  CLICKTOPAYLABEL: 'CLICK TO PAY',
  ADD_ITEMS_TO_REFILL: 'Add items to refill',
  REFILL: 'Refill',
  REFILL_EMPTY_CART_MSG:
    'Sorry, your order does not contain any items that are eligible for a refill',

  // DAISY CHAIN BUTTONS
  DAISY_FIRST_PERSON_YES: 'Yes, I’m the only one',
  DAISY_FIRST_PERSON_NO: 'No, I’m not the only one',
  DAISY_LAST_PERSON_YES: 'Yes, I’m the last one',
  DAISY_LAST_PERSON_NO: 'No, I’m not the last one',

  // Walkup Screen
  WHAT_WOULD_YOU_LIKE: 'What would you like to today',
  NO_OF_GUEST: 'Number of Guest In Your Party',
  HOW_MANY_CHILDREN: 'Of your guest,How Many are Children?',
  CHECK_AVAILABILITY: 'Check Availability',
  ADD_TO_WAIT_LIST: 'Add to Wait List',

  // Spot Screens
  ENTER_NAME_BELOW: 'Enter your name below',

  // Up sell
  MAY_WE_SUGGEST: 'May we suggest?',

  // Multicart

  // Pay Later

  // Other tips messages
  TIP_THIS_AMOUNT: 'Tip this amount:',

  // Tips Home
  CARD_CHARGED: 'If you proceed, your card will be charged.',
  PROCEED_ORDER_PLACED: 'If you proceed, your order will be placed.',
  DC_ORDER_MSG: 'Are you the last person to add to this order?',
  DC_ORDER_PLACE_MSG: 'Are you the only person sending an order right now?',
  HAS_PLACED_ORDER: 'has placed an order.',
  HAVE_PLACED_ORDER: 'have placed their orders.',
  HOW_MANY_PPL: 'Please select how many people will split this check:',
  RECEIPT_OPTION: 'Choose a receipt option',
  ADD_CARD: 'Add Card',
  REMOVE_CARD: 'Remove Card',
  SAVE_CARD: 'Save card',

  // Coupon Labels
  REMOVE_COUPON: 'Remove Coupon',
  APPLY_COUPON: 'Apply Coupon',

  // Coupon Screen
  CS_SCAN_QR_CODE: 'Scan Qr Code',
  CS_APPLY_CODE: 'Apply Code',
  CS_CANCEL: 'Cancel',

  // Charity
  CHARITY: 'Charity',
  APPLY_DONATION: 'Apply Donation',
  SKIP_DONATION: 'Skip Donation',
  CUSTOM: 'Custom',

  // LOYALTY_LOGIN: 'Login or Register for Rewards',

  // Pre auth
  HOLD: 'Hold',
  AUTHORIZE_CARD: 'Authorize card',
  I_AGREE: 'I Agree',
  KEEP_IT_OPEN: 'Keep it open',
  CONTINUE_AS_GUEST: 'Continue as Guest',

  // Menu Item Badge

  // Item Not Found
  ITEM_NOT_FOUND:
    'Search item not found. Please select a different item or contact the restaurant.',
  MENU_ITEM_NOT_ITEM:
    'Menu is currently not available. Please select a different menu or contact the restaurant.',

  // LOYALTY_WALLET_NAME: 'Name',
  // LOYALTY_UPDATE_NAME: 'Update Name',

  APARTMENT_NUMBER: 'Apartment or suite number (optional)',

  // Table Grouping Order
  JOIN_CHECK_AND_CONTINUE: 'Join check and continue',
  START_A_NEW_CHECK: 'Start a new check',
  JOIN_EXISTING_CHECK: 'Join existing check',
  JOIN_PARTY_WITH: 'Join a party with',

  // Address Management
  WALLET_ADDRESS: 'Address Book',
  WALLET_ADDRESS_NAME: 'Name',
  WALLET_LOOK_UP_ADDRESS: 'Lookup Address',
  WALLET_ADDRESS_UPDATE: 'Update',
  CART_BUTTON: 'SUBMIT ORDER',
  IMMEDIATE_ADD_TO_BAG: 'Add to Bag',
  CHECKOUT_DELIVER_TO: 'Deliver TO',
  CHECKOUT_SPOT_NUMBER: 'Spot Number',
  DATE: 'Date',
  PAY_WITH_STRIPE: 'PAY WITH CREDIT CARD',
  PAY_WITH_SPREEDLY: 'Pay with Spreedly',
  ITEM_SEARCH: 'Search Item',
  PICK_UP_TO: 'Pick up to',
  PICK_UP: 'Pick Up',
  OPTIONAL: 'optional',

  SELECT_DELIVERY_TIME: 'Select Delivery Time',
  SELECT_DELIVERY_DATE: 'Select Delivery Date',
  SELECT_PICK_UP_TIME: 'Select Pickup Time',
  SELECT_PICK_UP_DATE: 'Select Pickup Date',
  EDIT_ONL_ORDERS_YES: 'YES',
  EDIT_ONL_ORDERS_NO: 'NO',
  EDIT_ONL_ORDERS_OK: 'OK',
  EDIT_CU_ORDERS_SPOT_NUMBER: 'Spot Number',
  EDIT_CU_ORDERS_NAME: 'Name',
  DESKTOP_LOGIN: 'Login',
  DRIVE_THRU: 'Drive thru',
  TIP_ROUNDUP: 'Use tip to round up total',

  // Button Text for order page
  PAID_AMOUNT: 'PAID AMOUNT',

  // Others
  STAY: 'Stay',
  LOG_OUT: 'Logout',
  CANCEL_ORDER: 'CANCEL ORDER',

  // Cubby
  READY_TO_PICKUP: 'ORDERS READY FOR PICKUP',
  CUBBY: 'CUBBY#',

  // OrderUp
  PREPARING: 'PREPARING',
  READY: 'READY',
  SEE_COUNTER: 'SEE COUNTER',
  ORDER_STATUS: 'ORDER STATUS',
  NO_READY_PICK_UP_ORDERS: 'NO ORDERS READY FOR PICKUP',
  CONNECTING_TO_NEWTWORK: 'CONNECTING TO NETWORK',
  SOCKET_STATUS: 'STATUS',
  LAST_UPDATED_TIME: 'LAST UPDATE',
  TOTAL_CHECKS: 'TOTAL CHECKS',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',

  // kds
  ITEM: 'ITEM',
  ITEMS: 'ITEMS',
  LAST_HOUR: 'LAST HOUR',
  PAGE: 'PAGE',
  VIEW_ALL: 'View All',
  KDS_SELECT: 'SELECT',
  KDS_DROP: 'DROP',
  KDS_PRIORITIZE: 'Prioritize',
  KDS_SELECTED: 'Selected',
  KDS_DROP_CONTINUE: 'Continue',
  KDS_DROP_CANCEL: 'Cancel',
  KDS_MOVE_HERE: 'Move Here',

  KDS_LAYOUT: 'Layout',
  KDS_DUPLICATE: 'Duplicate',
  KDS_SUBMIT: 'Submit',
  KDS_BUMP_SELECTED: 'Bump Selected',
  KDS_BUMP: 'Bump',
  KDS_RECEIPE: 'Receipe',
  KDS_DRAG: 'Drag',
  KDS_RUSH: 'Rush',
  // payment terminal
  EMPLOYEE_NAME: 'Emp',
  CHECK_NUM: 'Check Num',

  // survey
  SURVEY_YES: 'Yes',
  SURVEY_NO: 'No',
  MAY_WE_CONTACT_YOU_ABOUT_YOUR_EXPERIENCE:
    'May we contact you about your experience?',
  WHAT_IS_THE_BEST_EMAIL_ADDRESS_TO_REACH_YOU:
    'What is the best email address to reach you?',
  YES_ALSO_CONTACT_ME_BY_PHONE: 'Yes, also contact me by phone',

  //Gamecard
  ADD_ON: 'Add On',
  PRICE: 'Price',
  STATUS: 'Status',
  MESSAGE: 'Message',
  PROCESS: 'Processed',
  CARD_BALANCE: 'Balance',
  TRANSACTIONS: 'Transactions',
  SHOW: 'Show All',
  CARDNAME: 'Card Name',
  CARDNUMBER: 'Card Number',
  GAMECARDSWIPE: 'Swipe',
  SENDANDACTIVATE: 'Send And Activate',
  SENDANDPAY: 'Send And Pay',

  //syncDatamsg
  CHECK_MEG: 'Check Has Been Modified',
  CHECK_SERVER: 'Please contact a server to complete payment.',
  PAYMENT_MEG: 'Payment not authorized for the full amount',
  PAYMENT_SERVER: 'Please contact a server to complete payment.',
  GIFT_CARD_ACTIVATION_MESSAGE:
    'Please select how you would like to receive your activation slip for your Gift Card(s)',
  GIFTCARD_EMAIL_RECEIPT: 'Email',

  // Payment Methods
  CREDIT_CARD_PAY: 'CREDIT CARD',
  CASH_PAY: 'CASH',
  GIFT_CARD_PAY: 'GIFT_CARD',
  DEFAULT_PAY: '',

  //CHECK PRESENTER
  CHECK_PRESENTER_PAY: 'Pay',
  CHECK_PRESENTER_NEXT_CHECK: 'Next',
  CHECK_PRESENTER_PREVIOUS_CHECK: 'Previous',

  //Landing Page
  LS_BACK: 'BACK',
  LS_CLEAR_SPLITS: 'CLEAR SPLITS',
  LS_SPLIT_BY_SEATS: 'SPLIT BY SEATS',
  LS_PAY_REMAINING_SEATS: 'PAY REMAINING SEATS',
  LS_SPLIT_BY_SELECTED_SEATS: 'Split By Selected Seats',
  LS_PAY_REMAINING_SELECTED_SEATS: 'PAY REMAINING SELECTED SEATS',
  LS_SPLIT_EVENLY: 'SPLIT EVENLY',
  LS_PAY_REMAINING_SPLITS: 'PAY REMAINING SPLITS',
  LS_PAY_FULL_CHECK: 'PAY FULL CHECK',
  LS_PAY_BALANCE: 'PAY BALANCE',

  //Payment Confirmation screen
  PC_PAYMENT_SUCCESSFUL: 'Payment Successful',
  PC_PAYMENT_COMPLETE: 'Payment Complete',
  PC_DUE: 'DUE',
  PC_DETAILS: 'Details',
  PC_CONTINUE: 'Continue',

  //Partial Tip Payment Screen
  PPES_CHECK: 'CHECK',
  PPES_TIP: 'TIP',
  PPES_TOTAL: 'TOTAL',
  PPES_AUTHORIZED: 'AUTHORIZED',
  PPES_TIP_COLLECTED: 'TIP COLLECTED',
  PPES_TIP_DUE: 'TIP DUE',
  PPES_UNCOLLECTED_TIP: 'UNCOLLECTED TIP',
  PPES_PAYMENT_PARTIALLY_APPROVED: 'Payment Partially Approved',
  PPES_PAYMENT_NOT_PROCESSED: 'Payment Not Processed!',
  PPES_CHECK_AMOUNT: 'CHECK AMOUNT',
  PPES_AUTHORIZED: 'AUTHORIZED',
  PPES_BALANCE_DUE: 'BALANCE DUE',
  PPES_PAY_BALANCE: 'Pay Balance',
  PPES_RETRY: 'Retry',

  //Receipt screen check details
  RECEIPT_DISCOUNT: 'Discount',
  RECEIPT_COUPON_KEY: 'Coupon',
  RECEIPT_OFFER_KEY: 'Offer',
  RECEIPT_SUBTOTAL: 'Subtotal',
  RECEIPT_TOTAL: 'Total',
  RECEIPT_TAX: 'Tax',
  RECEIPT_SERVICE_CHARGE: 'Service Charge',
  RECEIPT_TIP: 'Tip',
  RECEIPT_PAID: 'Paid',
  RECEIPT_GIFT_CARD: 'Gift card',
  RECEIPT_DUE: 'Due',
  RECEIPT_PAID_TIPS: 'Paid Tip',
  RECEIPT_UNPAID_TIP: 'Unpaid Tip',
  RECEIPT_PAYMENT: 'Payment',
  RECEIPT_ITEMS: 'Items',
  RECEIPT_TOTAL_DUE: 'Total Due',
  RECEIPT_TOTAL_PAID: 'Total Paid',
  RECEIPT_TOTAL_AMONUT: 'Total Amount',

  // Employee Initiate Payment Screen
  EPS_PAY: 'Pay',
  EPS_CANCEL: 'Cancel',
  EPS_APPLY: 'Apply',
  EPS_LOGIN: 'LOGIN',
  EPS_SELECT_PAYMENT_METHOD: 'SELECT PAYMENT METHOD',
  EPS_PAY_CASH: 'PAY CASH',
  EPS_PAY_GIFT_CARD: 'Pay Gift Card',
  EPS_PAY_CREDIT_CARD: 'PAY CREDIT CARD',
  EPS_CREDIT_CARD: 'CREDIT CARD',
  EPS_GIFT_CARD: 'GIFT CARD',
  EPS_CASH: 'Cash',
  EPS_AMOUNT_COLLECTED: 'AMOUNT COLLECTED',
  EPS_CHANGE: 'Change',
  EPS_PAYMENT: 'PAYMENT',
  EPS_COLLECTED: 'Collected',
  EPS_CREDIT_CARD_PAYMENT: 'Credit Card Payment',
  EPS_REDEEM: 'Redeemed',
  EPS_BALANCE: 'Balance',
  EPS_DUE: 'DUE',
  EPS_NO_PAYEMNTS: 'No Payments',
  EPS_SELECT_A_PAYMENT_METHOD: 'Select a payment method from above',

  //TIP SCREEN
  TIP_CHECKTOTAL: 'Your check total is',
  TIP_SELECT: 'Please select a tip',
  TIP_OTHER: 'Other Amount',
  TIP_NO_TIP: 'No Tip',
  TIP_AMOUNT: 'Amount',
  TIP_PERCENT: 'Percent',
  TIP: 'Tip',
  TIP_NO: 'No',
  TIP_YES: 'Yes',
  TIP_VALIDATION_MESSAGE: 'Select tips or enter a valid tip amount or 0',

  //SPLIT BY SELECTED SEATS
  SBSS_CREATE_CHECK: 'Create check',
  SBSS_SEATS_INC_DEC: 'Seat',
  SBSS_CHECK: 'CHECK',
  SBSS_SAVE: 'SAVE',
  SBSS_BACK: 'BACK',
  SBSS_CLICK_TO_PAY: 'CLICK TO PAY',
  SBSS_MOVE: 'MOVE',
  SBSS_SUBMIT: 'Submit',
  SBSS_CANCEL: 'Cancel',
  SBSS_ALL_CHECKS: 'ALL CHECKS',
  SBSS_OK: 'OK',

  //SPLIT BY SEATS
  SBS_SEATS_INC_DEC: 'SEATS + /-',
  SBS_CHECK: 'Check',
  SBS_SAVE: 'SAVE',
  SBS_BACK: 'BACK',
  SBS_CLICK_TO_PAY: 'CLICK TO PAY',
  SBS_MOVE: 'MOVE',
  SBS_SUBMIT: 'Submit',
  SBS_CANCEL: 'Cancel',
  ITEMS: 'Items',
  QTY: 'Qty',
  CHECK_NAME: 'Check Name',

   // Gift Card Screen
  GS_CLICK_TO_SWIPE_CARD: "CLICK TO SWIPE CARD",
  GS_APPLY: "APPLY",
  GS_CANCEL: "CANCEL",
  GS_CLICK_TO_ENTER_CARD_NUMBER: "Click To Enter Card Number",
  GS_CLICK_TO_ENTER_SECURITY_CODE: "Click To Enter Security Code",

  //Loyalty screen
  LS_PHONE: 'Phone',
  LS_EMAIL: 'Email',
  LS_ACCOUNT: 'Account #',
  LS_SIGN_IN_TO_LOYALTY: "SIGN IN TO LOYALTY",
  LS_JOIN_LOYALTY_PROGRAM: "Would you like to join our loyalty program today?",
  LS_JOIN_NOW: "JOIN NOW",
  LS_ALREADY_A_MEMBER: "ALREADY A MEMBER",
  LS_NOT_NOW: "NOT NOW",
  LS_SIGN_UP_TO_LOYALTY: "SIGN UP TO LOYALTY",
  LS_JOIN_TODAY: "How would you like to join today?",
  LS_SIGN_UP: "Sign Up",
  LS_SIGN_IN_TODAY: "How would you like to sign in today?",
  LS_SIGN_IN: "Sign In",
  LS_CANCEL: "CANCEL",
  LS_CONTINUE: "CONTINUE",
  LS_WELCOME_BACK: "Welcome back!",
  LS_VALUE: "Value",
  LS_SKIP_OFFER: "SKIP OFFER",
  LS_USE_OFFER: "USE OFFER",
  LS_CHANGE_ACCOUNT: "Change Account",

  // Receipt screen
  RS_NO_RECEIPT: "No Receipt",
  RS_DUE: "Due",
  RS_NEED_A_RECEIPT: "Need a Receipt?",
  RS_YOU_ARE_ALL_DONE: "You're all done!",
  RS_NO: "No",
  RS_YES: "Yes",

};
